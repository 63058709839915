import AppButton from '../../components/AppButton';

import {
    PaymentElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { baseURL } from '../../../helpers/api';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { Spinner } from 'react-bootstrap';

interface IProps {
    clientSecret: string;
}

const CheckoutForm = ({ clientSecret }: IProps) => {
    const stripe = useStripe();

    const elements = useElements();

    const [stripeLoading, setStripeLoading] = useState(true);

    const { order } = useSelector((state: StateType) => state.payment.order);

    const [isLoading, setIsLoading] = useState(false);

    const [paymentIntentId, setPaymentIntentId] = useState<string>();

    const showToast = (message: string, type: 'success' | 'error') => {
        if (type === 'success') {
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER
            });
        }

        if (type === 'error') {
            toast.error(message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };

    useEffect(() => {
        if (!!stripe && !!clientSecret) {
            stripe
                .retrievePaymentIntent(clientSecret)
                .then(({ paymentIntent }) => {
                    setPaymentIntentId(paymentIntent?.id);
                });
        }
    }, [stripe, clientSecret]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (
            stripe &&
            elements &&
            paymentIntentId &&
            order?.user?._id &&
            order._id
        ) {
            setIsLoading(true);

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url:
                        baseURL +
                        `/payment/transaction/stripe/success?user_id=${order.user._id}&order_id=${order._id}&intent_id=${paymentIntentId}`
                }
            });

            if (
                error.type === 'card_error' ||
                error.type === 'validation_error'
            ) {
                showToast(error?.message ?? '', 'error');
            } else {
                showToast('An unexpected error occured.', 'error');
            }

            setIsLoading(false);
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
                id="payment-element"
                onReady={() => {
                    setStripeLoading(false);
                }}
            />

            {stripeLoading && (
                <div className="d-flex align-items-center mb-3">
                    <span className="mr-2">Loading...</span>
                    <Spinner animation="border" size="sm" />
                </div>
            )}

            {!stripeLoading && (
                <AppButton
                    variant="primary"
                    disabled={isLoading || !stripe || !elements}
                    type="submit"
                    loading={isLoading}
                    className="mt-4"
                >
                    Confirm Payment
                </AppButton>
            )}
        </form>
    );
};

export default CheckoutForm;
