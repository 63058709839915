import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import {
    $FIXME,
    GET_EXPIRED_TEMPLATES,
    GET_FEATURED_TEMPLATES,
    GET_SIMILAR_TEMPLATES,
    GET_SUBSCRIBED_TEMPLATE,
    GET_SUBSCRIBED_TEMPLATES,
    GET_TEMPLATE,
    GET_TEMPLATES,
    GET_TEMPLATE_REVIEWS,
    RESET_TEMPLATE_REVIEWS,
    RESET_TEMPLATE_STATE,
    UPDATE_SUBSCRIBED_TEMPLATE
} from '../helpers/constants';
import request from '../helpers/request';
import {
    ICreateTemplateReviewBody,
    ISaveUserProgressBody,
    ISubscribedTemplate,
    ISubscribedTemplatesParams,
    ITemplateParams,
    ITemplateReviewParams
} from '../interfaces/Template';
import { setErrors } from './errorActions';

export const getTemplates =
    (params: ITemplateParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_TEMPLATES,
            payload: { templatesLoading: true }
        });

        request.getTemplates(params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_TEMPLATES,
                payload: { templates: res.data, templatesLoading: false }
            });
        });
    };

export const getFeaturedTemplates =
    (params: ITemplateParams) => async (dispatch: Dispatch) => {
        request
            .getTemplates({ ...params, featured: true })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_FEATURED_TEMPLATES,
                    payload: res.data
                });
            });
    };

export const getTemplateBySlug =
    (slug: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_TEMPLATE,
            payload: { templateLoading: true }
        });

        return request
            .getTemplateBySlug(slug)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_TEMPLATE,
                    payload: { template: res.data, templateLoading: false }
                });

                return Promise.resolve(res.data?._id);
            })
            .catch(() => {
                dispatch({
                    type: GET_TEMPLATE,
                    payload: { templateLoading: false }
                });

                return Promise.reject();
            });
    };

export const getTemplateReviews =
    (id: string, params: ITemplateReviewParams) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: GET_TEMPLATE_REVIEWS,
            payload: { reviewsLoading: true }
        });

        request.getTemplateReviews(id, params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_TEMPLATE_REVIEWS,
                payload: { reviews: res.data, reviewsLoading: false }
            });
        });
    };

export const createTemplateReivew =
    (body: ICreateTemplateReviewBody) => async (dispatch: $FIXME) => {
        return request
            .createTemplateReview(body)
            .then((res: AxiosResponse) => {
                toast.success('Your review has been posted.', {
                    position: toast.POSITION.TOP_CENTER
                });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch(setErrors(err.response?.data.message));

                return Promise.reject(err.response?.data.message);
            });
    };

export const resetTemplateReviews = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_TEMPLATE_REVIEWS
    });
};

export const resetTemplateState = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_TEMPLATE_STATE
    });
};

export const getSubscribedTemplates =
    (params: ISubscribedTemplatesParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_SUBSCRIBED_TEMPLATES,
            payload: { subscribedLoading: true }
        });

        request
            .getSubscribedTemplates(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_SUBSCRIBED_TEMPLATES,
                    payload: { subscribed: res.data, subscribedLoading: false }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_SUBSCRIBED_TEMPLATES,
                    payload: { subscribedLoading: false }
                });
            });
    };

export const getSubscribedTemplate =
    (slug: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_SUBSCRIBED_TEMPLATE,
            payload: {
                subscribedTemplateLoading: true
            }
        });

        return request
            .getSubscribedTemplate(slug)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_SUBSCRIBED_TEMPLATE,
                    payload: {
                        subscribedTemplate: res.data,
                        subscribedTemplateLoading: false
                    }
                });

                return Promise.resolve();
            })
            .catch(() => {
                dispatch({
                    type: GET_SUBSCRIBED_TEMPLATE,
                    payload: {
                        subscribedTemplateLoading: false
                    }
                });

                return Promise.reject();
            });
    };

export const saveUserTemplateProgress =
    (body: ISaveUserProgressBody, id: string) => async (dispatch: $FIXME) => {
        return request
            .saveUserTemplateProgress(id, body)
            .then((res: AxiosResponse) => {
                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch(setErrors(err.response?.data.message));
                return Promise.reject(err.response?.data.message);
            });
    };

export const getSimilarTemplates =
    (id: string) => async (dispatch: Dispatch) => {
        request.getSimilarTemplates(id).then((res: AxiosResponse) => {
            dispatch({
                type: GET_SIMILAR_TEMPLATES,
                payload: res.data
            });
        });
    };

export const getExpiredTemplates =
    (params: ISubscribedTemplatesParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_EXPIRED_TEMPLATES,
            payload: { expiredTemplatesLoading: true }
        });

        request
            .getSubscribedTemplates({ ...params, expired: true })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_EXPIRED_TEMPLATES,
                    payload: {
                        expiredTemplates: res.data,
                        expiredTemplatesLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_EXPIRED_TEMPLATES,
                    payload: { expiredTemplatesLoading: false }
                });
            });
    };

export const updateSubscribedTemplate =
    (body: ISubscribedTemplate) => async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_SUBSCRIBED_TEMPLATE,
            payload: body
        });
    };
