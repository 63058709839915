import { Row, Col, Spinner, Modal } from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import GetOffers from '../templateSearch/GetOffers';
import AppRadio from '../../components/AppRadio';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Esewa from '../../../assets/images/payments/eSewa.png';
import { useHistory, Redirect } from 'react-router-dom';
import PaymentSummary from './PaymentSummary';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
// import { CellPay } from '../../../assets/images/icons';
import { baseURL } from '../../../helpers/api';
import {
    $FIXME,
    esewaClientId,
    esewaURL,
    fonepayClientId,
    fonePayURL,
    redirectUrl,
    stripeKey
} from '../../../helpers/constants';
import { getAllOffers } from '../../../actions/offerActions';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import request from '../../../helpers/request';
import { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { Fonepay } from '../../../assets/images/icons';
import QRCode from 'react-qr-code';
import { setErrors } from '../../../actions/errorActions';
import { IQrTransactionStatus } from '../../../interfaces/Payment';
import { IoQrCodeOutline } from 'react-icons/io5';
import { CgCloseO } from 'react-icons/cg';

interface IProps {}

const Checkout = (props: IProps) => {
    const stripePromise = loadStripe(stripeKey);

    const { order } = useSelector((state: StateType) => state.payment.order);

    const [selectedPayment, setSelectedPayment] = useState('merchant');

    const [clientSecret, setClientSecret] = useState<string>();

    const [qrLoading, setQrLoading] = useState(false);

    const [qrCode, setQrCode] = useState<string>();

    const [paymentProcessing, setPaymentProcessing] = useState(false);

    const [socketURL, setSocketURL] = useState<string>();

    const [qrModalOpen, setQrModalOpen] = useState(false);

    const dispatch = useDispatch();

    const history = useHistory();

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Template',
            link: '/template'
        },
        {
            label: 'Cart',
            link: '/cart',
            active: true
        }
    ];

    useEffect(() => {
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));

        if (order?._id && order.user?._id) {
            request
                .getStripeIntent({
                    order_id: order._id,
                    user_id: order.user._id
                })
                .then((res: AxiosResponse) => {
                    setClientSecret(res.data.clientSecret);
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                });
        }
    }, [dispatch, order?._id, order?.user?._id]);

    useEffect(() => {
        if (!qrCode && qrModalOpen && order?._id && order.fonepay_qr_hash) {
            setQrLoading(true);

            request
                .generateQRCode({
                    order_id: order._id,
                    qr_hash: order.fonepay_qr_hash
                })
                .then((res) => {
                    setQrCode(res.data?.qrMessage);
                    setSocketURL(res.data?.thirdpartyQrWebSocketUrl);

                    setQrLoading(false);
                })
                .catch((err: AxiosError) => {
                    dispatch(
                        setErrors(
                            err.response?.data?.message ??
                                'Something went wrong'
                        )
                    );

                    setQrLoading(false);
                });
        }
    }, [
        dispatch,
        selectedPayment,
        order?._id,
        order?.fonepay_qr_hash,
        qrCode,
        qrModalOpen
    ]);

    useEffect(() => {
        if (socketURL) {
            const client = new WebSocket(socketURL);

            client.onmessage = (message) => {
                const response = JSON.parse(message?.data as string);

                const transactionStatus: IQrTransactionStatus = JSON.parse(
                    response?.transactionStatus
                );

                if (
                    transactionStatus.paymentSuccess !== undefined &&
                    order?._id
                ) {
                    setPaymentProcessing(true);

                    request
                        .qrStatus(order._id)
                        .then((res) => {
                            if (res.data?.status === 'success') {
                                history.push(
                                    `/checkout/confirmed?PRN=${res.data?.prn}&AMT=${res.data?.totalTransactionAmount}&order_id=${order._id}`
                                );
                            } else if (res.data?.staus === 'failed') {
                                history.push(`/checkout/failed`);
                            }

                            setPaymentProcessing(false);
                        })
                        .catch((err: AxiosError) => {
                            dispatch(
                                setErrors(
                                    err.response?.data?.message ??
                                        'Something went wrong. Try again later or use different payment method.'
                                )
                            );

                            setPaymentProcessing(false);
                        });
                }
            };
        }
    }, [dispatch, socketURL, order?._id, history]);

    const handlePaymentChange = (value: string) => {
        setSelectedPayment(value);
    };

    const handlePayment = (path: string, params: $FIXME) => {
        var form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', path);
        for (var key in params) {
            var hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);
            form.appendChild(hiddenField);
        }
        document.body.appendChild(form);
        form.submit();
    };

    if (!order) {
        return <Redirect to="/cart" />;
    }

    const appearance: Appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#ed2b30',
            colorBackground: '#ffffff',
            colorText: '#1c2026',
            borderRadius: '0px',
            fontSizeBase: '17px'
        }
    };

    return (
        <MainLayout>
            <div className="container-xxl container-xl checkout-container">
                <AppBreadcrumbs items={breadcrumbItems} />

                <div className="mb-3 pt-3">
                    <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                        PAYMENT METHOD
                    </h2>

                    <p className="text-gray text-14 mb-0">
                        Select payment method and start paying
                    </p>
                </div>

                <Row className="mb-5">
                    <Col className="mb-5">
                        <div className="payment-container mb-5 px-4 pt-4 pb-5">
                            <div className="mb-4">
                                <AppRadio
                                    value="merchant"
                                    selected={selectedPayment}
                                    text="Merchant Payment"
                                    onChange={handlePaymentChange}
                                    labelClassName="text-neutrif text-600 text-18 text-dark-2"
                                />

                                <div
                                    className="merchant-container flex-wrap"
                                    style={{
                                        display:
                                            selectedPayment === 'merchant'
                                                ? 'flex'
                                                : 'none'
                                    }}
                                >
                                    {/* <div className="mr-3">
                                        <Button
                                            variant="outline-primary"
                                            style={{
                                                backgroundColor: 'white'
                                            }}
                                            onClick={() => {
                                                handlePayment(
                                                    'https://app.cellpay.com.np/test_merchant_api/',
                                                    {
                                                        merchant_id:
                                                            CELLPAY_MERCHANTID,
                                                        description:
                                                            order?._id ?? '',
                                                        amount:
                                                            order?.total_amount?.toString() ??
                                                            '',
                                                        transaction_type: '1',
                                                        invoice_number:
                                                            order?._id ?? '',
                                                        success_callback:
                                                            baseURL +
                                                            `/payment/transaction/cellpay/success?user_id=${order?.user?._id}&order_id=${order?._id}`,
                                                        failure_callback: `https://oxstrength-web.asterdio.xyz/cart/checkout`,
                                                        cancel_callback: `https://oxstrength-web.asterdio.xyz/cart/checkout`
                                                    }
                                                );
                                            }}
                                        >
                                            <CellPay width={70} />
                                        </Button>
                                    </div> */}
                                    <div className="mr-3 mb-3">
                                        <Button
                                            variant="outline-primary"
                                            style={{
                                                backgroundColor: 'white'
                                            }}
                                            onClick={() => {
                                                if (
                                                    order.total_amount &&
                                                    order._id &&
                                                    order.user?._id
                                                )
                                                    handlePayment(esewaURL, {
                                                        amt: order.total_amount,
                                                        psc: 0,
                                                        pdc: 0,
                                                        txAmt: 0,
                                                        tAmt: order.total_amount,
                                                        pid: order._id,
                                                        scd: esewaClientId,
                                                        su:
                                                            baseURL +
                                                            `/payment/transaction/esewa/success?q=su&user_id=${order.user._id}`,
                                                        fu: `${redirectUrl}/checkout/failed`
                                                    });
                                            }}
                                        >
                                            <img
                                                alt="esewa"
                                                src={Esewa}
                                                width={70}
                                            />
                                        </Button>
                                    </div>

                                    <div className="mr-3 mb-3">
                                        <Button
                                            variant="outline-primary"
                                            style={{
                                                backgroundColor: 'white'
                                            }}
                                            onClick={() => {
                                                if (
                                                    order.total_amount &&
                                                    order._id &&
                                                    order.user?._id &&
                                                    order.createdAt &&
                                                    order.fonepay_hash &&
                                                    order.remarks &&
                                                    order.user._id &&
                                                    order.identifier
                                                )
                                                    handlePayment(fonePayURL, {
                                                        PID: fonepayClientId,
                                                        MD: 'P',
                                                        AMT: order.total_amount,
                                                        CRN: 'NPR',
                                                        DT: dayjs(
                                                            order.createdAt
                                                        ).format('MM/DD/YYYY'),
                                                        R1: order._id,
                                                        R2: order.remarks,
                                                        DV: order.fonepay_hash,
                                                        RU:
                                                            baseURL +
                                                            `/payment/transaction/fonepay/web/success?user_id=${order.user._id}&oid=${order._id}`,
                                                        PRN: order.identifier
                                                    });
                                            }}
                                        >
                                            <Fonepay width={70} />
                                        </Button>
                                    </div>

                                    <div className="mr-3 mb-3">
                                        <Button
                                            variant="outline-primary"
                                            style={{
                                                backgroundColor: 'white'
                                            }}
                                            onClick={() => {
                                                setQrModalOpen(true);
                                            }}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <IoQrCodeOutline
                                                    size={30}
                                                    className="text-red"
                                                />
                                                <span className="text-dark-blue pt-1 pl-2 text-500 text-16">
                                                    QR
                                                </span>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <AppRadio
                                    value="card"
                                    selected={selectedPayment}
                                    onChange={handlePaymentChange}
                                    labelClassName="text-neutrif text-600 text-18 text-dark-2"
                                    text="Card payment"
                                />

                                <div
                                    style={{
                                        display:
                                            selectedPayment === 'card'
                                                ? 'block'
                                                : 'none'
                                    }}
                                >
                                    {clientSecret && (
                                        <Elements
                                            stripe={stripePromise}
                                            options={{
                                                clientSecret,
                                                appearance
                                            }}
                                        >
                                            <CheckoutForm
                                                clientSecret={clientSecret}
                                            />
                                        </Elements>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} className="mb-5">
                        <PaymentSummary />
                    </Col>
                </Row>
            </div>
            <GetOffers offerType="all" />
            <GetNotified />

            {paymentProcessing ? (
                <div
                    className="d-flex align-items-center justify-content-center position-fixed"
                    style={{
                        inset: 0,
                        zIndex: 9999,
                        backgroundColor: 'rgba(0,0,0,0.6)'
                    }}
                >
                    <Spinner animation="border" className="text-white" />
                </div>
            ) : (
                ''
            )}

            <Modal
                show={qrModalOpen}
                onHide={() => {
                    setQrModalOpen(false);
                }}
                size="sm"
                centered
                contentClassName="rounded"
            >
                <Modal.Header className="px-0 mx-3">
                    <span className="text-600 text-gray-3 text-20">
                        Scan QR code
                    </span>
                    <CgCloseO
                        size={28}
                        className="text-gray text-hover-red cursor-pointer"
                        onClick={() => {
                            setQrModalOpen(false);
                        }}
                    />
                </Modal.Header>
                <Modal.Body>
                    {qrLoading ? (
                        <div className="d-flex align-items-center mb-3">
                            <span className="mr-2">Loading...</span>

                            <Spinner animation="border" size="sm" />
                        </div>
                    ) : qrCode ? (
                        <div className="text-center">
                            <p className="text-inter text-600 text-14 text-gray-3">
                                Note: Please stay on this page until your
                                transaction is completed.
                            </p>

                            <QRCode
                                value={qrCode}
                                size={200}
                                fgColor="#1c2026"
                                className="mb-3"
                            />

                            <p className="text-neutrif text-500 text-16 text-dark-blue">
                                Scan this QR from your Fonepay, eSewa, IME Pay
                                or your mobile banking app to make payment.
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                </Modal.Body>
            </Modal>
        </MainLayout>
    );
};

export default Checkout;
