import { Cart } from '../interfaces/Cart';

export const formatNumber = (number?: number) =>
    number?.toLocaleString('en-IN', { maximumFractionDigits: 2 });

export const getExtension = (filename: string) => filename.split('.').pop();

export const getPrice = (cart: Cart) => {
    switch (cart.onModel) {
        case 'Template':
            return {
                price: cart.ref?.price,
                discounted_price: cart.ref?.discounted_price
            };
        case 'Coaching':
            const coachingRate = cart.ref?.rates?.find(
                (rate) => rate.trainer === cart.coaching_trainer?._id
            );

            if (cart.coaching_plan === '4 Weeks Plan') {
                return {
                    price: coachingRate?.w4_rate,
                    discounted_price: coachingRate?.discounted_w4_rate
                };
            }

            if (cart.coaching_plan === '12 Weeks Plan') {
                return {
                    price: coachingRate?.w12_rate,
                    discounted_price: coachingRate?.discounted_w12_rate
                };
            }

            if (cart.coaching_plan === '24 Weeks Plan') {
                return {
                    price: coachingRate?.w24_rate,
                    discounted_price: coachingRate?.discounted_w24_rate
                };
            }

            return;

        case 'Consultation':
            const consultationRate = cart.ref?.rates?.find(
                (rate) => rate.trainer === cart.consultation_trainer?._id
            );

            if (cart.consultation_plan === 'Half Hour Plan') {
                return {
                    price: consultationRate?.half_hour_rate,
                    discounted_price:
                        consultationRate?.discounted_half_hour_rate
                };
            }

            if (cart.consultation_plan === 'Hour Plan') {
                return {
                    price: consultationRate?.hour_rate,
                    discounted_price: consultationRate?.discounted_hour_rate
                };
            }

            return;

        case 'Product':
            const productRate = cart.ref?.rates?.find(
                (rate) => rate.size === cart.product_size
            );

            return {
                price: productRate?.price,
                discounted_price: productRate?.discounted_price
            };
    }
};
