import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { $FIXME, defaultImage } from '../../../helpers/constants';
import { Cart } from '../../../interfaces/Cart';
import { StateType } from '../../../reducers';
import { formatNumber } from '../../../utils/functions';
import Counter from '../../components/Counter';

const OrderDetailTable = () => {
    const { orders } = useSelector((state: StateType) => state.user);

    const { id }: $FIXME = useParams();

    const carts = orders?.orders.find((order) => order._id === id)?.carts;

    const calculatePrice = ({
        ref,
        onModel,
        consultation_trainer,
        consultation_plan,
        coaching_trainer,
        coaching_plan,
        product_size,
    }: Cart) => {
        let rates = ref?.rates ?? [];
        let discount = ref?.discount;
        let price = ref?.price ?? 0;
        let discountedPrice = ref?.discounted_price ?? 0;

        if (onModel === 'Consultation') {
            let rate = rates?.find(
                ({ trainer }) => trainer === consultation_trainer?._id
            );

            if (consultation_plan === 'Hour Plan') {
                let hourRate = rate?.hour_rate ?? 0;

                return {
                    price: hourRate,
                    discountPrice:
                        discount?.active && discount.discount_percentage
                            ? hourRate -
                              hourRate * (discount.discount_percentage / 100)
                            : undefined,
                };
            } else if (consultation_plan === 'Half Hour Plan') {
                let halfHourRate = rate?.half_hour_rate ?? 0;

                return {
                    price: halfHourRate,
                    discountPrice:
                        discount?.active && discount.discount_percentage
                            ? halfHourRate -
                              halfHourRate *
                                  (discount.discount_percentage / 100)
                            : undefined,
                };
            }

            return {
                price: 0,
                discountPrice: undefined,
            };
        } else if (onModel === 'Coaching') {
            let rate = rates?.find(
                ({ trainer }) => trainer === coaching_trainer?._id
            );

            let price = 0;

            if (coaching_plan === '4 Weeks Plan') {
                price = rate?.w4_rate ?? 0;
            } else if (coaching_plan === '12 Weeks Plan') {
                price = rate?.w12_rate ?? 0;
            } else if (coaching_plan === '24 Weeks Plan') {
                price = rate?.w24_rate ?? 0;
            }

            return {
                price: price,
                discountPrice:
                    discount?.active && discount.discount_percentage
                        ? price - price * (discount.discount_percentage / 100)
                        : undefined,
            };
        } else if (onModel === 'Product') {
            let rate = rates?.find(({ size }) => size === product_size);

            return {
                price: rate?.price ?? 0,
                discountPrice:
                    discount?.active && discount.discount_percentage
                        ? rate?.discounted_price
                        : undefined,
            };
        } else {
            return {
                price: price,
                discountPrice:
                    discount?.active &&
                    discount.discount_percentage &&
                    discountedPrice < price
                        ? discountedPrice
                        : undefined,
            };
        }
    };

    if (!orders) {
        return <Redirect to="/dashboard/orders" />;
    }

    return (
        <Table responsive borderless className="order-table">
            <thead>
                <tr>
                    <th className="text-neutrif text-600 text-14 text-dark-2">
                        Product/Package
                    </th>
                    <th className="text-neutrif text-600 text-14 text-dark-2">
                        Price
                    </th>
                    <th className="text-neutrif text-600 text-14 text-dark-2">
                        Unit
                    </th>
                    <th className="text-neutrif text-600 text-14 text-dark-2">
                        Subtotal
                    </th>
                    <th className="text-neutrif text-600 text-14 text-dark-2">
                        Order For
                    </th>
                </tr>
            </thead>
            <tbody>
                {carts?.map((cart) => {
                    const {
                        onModel,
                        ref,
                        consultation_trainer,
                        coaching_trainer,
                        coaching_plan,
                        consultation_plan,
                        unit,
                        self_order,
                        relation,
                        name,
                        email,
                        mobile_num,
                    } = cart;

                    return (
                        <tr>
                            <td style={{ width: 300 }}>
                                <div className="d-flex">
                                    <div className="order-img-wrapper mr-3">
                                        <img
                                            src={
                                                onModel === 'Product'
                                                    ? ref?.images &&
                                                      ref.images.length > 0
                                                        ? ref.images[0]
                                                        : defaultImage
                                                    : !!ref?.cover
                                                    ? ref.cover
                                                    : defaultImage
                                            }
                                            alt="order-img"
                                        />
                                    </div>
                                    <div>
                                        <p className="text-700 text-14 mb-0 text-dark-blue">
                                            {ref?.title}
                                        </p>
                                        <p className="text-500 text-14 mb-2 text-dark-blue">
                                            {(() => {
                                                if (onModel === 'Product') {
                                                    return ref?.product_brand
                                                        ?.name;
                                                } else if (
                                                    onModel === 'Template'
                                                ) {
                                                    return ref
                                                        ?.training_category
                                                        ?.name;
                                                } else if (
                                                    onModel === 'Coaching'
                                                ) {
                                                    return coaching_plan;
                                                } else if (
                                                    onModel === 'Consultation'
                                                ) {
                                                    return consultation_plan;
                                                } else {
                                                    return;
                                                }
                                            })()}
                                        </p>
                                        <p className="text-14 text-gray mb-0">
                                            {(() => {
                                                if (onModel === 'Product') {
                                                    return ref?.product_category
                                                        ?.name;
                                                } else if (
                                                    onModel === 'Consultation'
                                                ) {
                                                    return `By: ${consultation_trainer?.name}`;
                                                } else if (
                                                    onModel === 'Coaching'
                                                ) {
                                                    return `By: ${coaching_trainer?.name}`;
                                                } else {
                                                    return;
                                                }
                                            })()}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {calculatePrice(cart).discountPrice ? (
                                    <>
                                        <p className="text-600 text-dark-2 mb-1">
                                            Rs.{' '}
                                            {formatNumber(
                                                calculatePrice(cart)
                                                    .discountPrice
                                            )}
                                        </p>
                                        <p className="text-400 text-14 text-gray-1 mb-1">
                                            <s>
                                                Rs.{' '}
                                                {formatNumber(
                                                    calculatePrice(cart).price
                                                )}
                                            </s>
                                        </p>
                                    </>
                                ) : (
                                    <p className="text-600 text-dark-2 mb-1">
                                        Rs.{' '}
                                        {formatNumber(
                                            calculatePrice(cart).price
                                        )}
                                    </p>
                                )}
                            </td>
                            <td>
                                <Counter
                                    min={1}
                                    max={ref?.stock}
                                    value={unit}
                                    disabled
                                />
                            </td>
                            <td className="">
                                <p className="text-600 text-dark-2">
                                    Rs.{' '}
                                    {formatNumber(
                                        (cart.unit ?? 0) *
                                            (calculatePrice(cart)
                                                .discountPrice ??
                                                calculatePrice(cart).price)
                                    )}
                                </p>
                            </td>
                            <td>
                                {!self_order ? (
                                    <div style={{ minWidth: 180 }}>
                                        <p className="text-700 text-14 mb-0 text-dark-blue">
                                            {name}
                                        </p>
                                        <p className="text-500 text-14 mb-0 text-dark-blue">
                                            {email}
                                        </p>
                                        <p className="text-14 text-gray mb-0">
                                            {`${relation} - ${mobile_num}`}
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                        <p className="text-600 text-14 mb-0 text-dark-blue">
                                            Self
                                        </p>
                                    </div>
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default OrderDetailTable;
