import {
    Row,
    Col,
    Dropdown,
    DropdownButton,
    Button,
    Spinner
} from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppButton from '../../components/AppButton';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import AppTemplateCard from '../../components/AppTemplateCard';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import Offers from '../home/Offers';
import GetOffers from '../templateSearch/GetOffers';
import { BsArrowLeft } from 'react-icons/bs';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import SimilarTemplateList from './SimilarTemplateList';
import AppInput from '../../components/AppInput';
import React, { useState } from 'react';
import AppRatingCard from '../../components/AppRatingCard';
import AppRadio from '../../components/AppRadio';
import Rating from 'react-rating';
import {
    $FIXME,
    defaultImage,
    phoneRegExp,
    relation,
    sortReviewFilter
} from '../../../helpers/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createTemplateReivew,
    getSimilarTemplates,
    getTemplateBySlug,
    getTemplateReviews,
    resetTemplateReviews
} from '../../../actions/templateActions';
import { AppDispatch, StateType } from '../../../reducers';
import { ICreateTemplateReviewBody } from '../../../interfaces/Template';
import { IAddCartBody, ITemplateOrderBody } from '../../../interfaces/Product';
import TemplateOrderForm from './TemplateOrderForm';
import { addToCart } from '../../../actions/cartActions';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { formatNumber } from '../../../utils/functions';
import TruncateMarkup from 'react-truncate-markup';
import { getTemplateOffers } from '../../../actions/offerActions';
import { IExpressCheckoutParams } from '../../../interfaces/Cart';
import qs from 'query-string';

interface IProps {}

const TemplateDetails = (props: IProps) => {
    const [selectedRadio, setSelectedRadio] = useState<string>('yes');

    const [reviewSort, setReviewSort] = useState(sortReviewFilter[0].option);

    const [addReviewOpen, setAddReviewOpen] = useState(false);

    const [myReview, setMyReview] = useState<ICreateTemplateReviewBody>({});

    const [readMore, setReadMore] = useState(false);

    const { template, templateLoading, reviews, reviewsLoading } = useSelector(
        (state: StateType) => state.template
    );

    const auth = useSelector((state: StateType) => state.auth);

    const { carts } = useSelector((state: StateType) => state.cart.carts);

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const location = useLocation();

    const { slug }: $FIXME = useParams();

    const reviewResultsPerPage = 2;

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Search',
            link: '/template'
        },
        {
            label: 'Template Details',
            link: `/template/${slug}`,
            active: true
        }
    ];

    useEffect(() => {
        dispatch(getTemplateBySlug(slug)).then((id) => {
            dispatch(getSimilarTemplates(id));
        });

        dispatch(getTemplateOffers({ resultsPerPage: '10', status: 'active' }));

        return () => {
            dispatch(resetTemplateReviews());
        };
    }, [dispatch, slug]);

    useEffect(() => {
        if (template._id)
            dispatch(
                getTemplateReviews(template._id, {
                    sort: reviewSort,
                    resultsPerPage: reviewResultsPerPage.toString()
                })
            );
    }, [template._id, reviewSort, dispatch]);

    const handleTemplateRadioChange = (value: string) => {
        setSelectedRadio(value);
    };

    const isReviewValid = () => {
        if (myReview.review && myReview.stars) {
            return true;
        }

        return false;
    };

    const handleRating = (value: number) => {
        setMyReview({
            ...myReview,
            stars: value.toString()
        });
    };

    const handleReviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMyReview({
            ...myReview,
            review: e.target.value
        });
    };

    const handleReviewSubmit = () => {
        dispatch(
            createTemplateReivew({ ...myReview, template_id: template._id })
        ).then(() => {
            if (template._id) {
                dispatch(resetTemplateReviews());
                dispatch(
                    getTemplateReviews(template._id, {
                        sort: reviewSort,
                        resultsPerPage: reviewResultsPerPage.toString()
                    })
                );

                setMyReview({ stars: undefined, review: '' });
            }
        });
    };

    const handleLoadMoreReviews = () => {
        if (reviews.currentPage !== reviews.totalPage)
            if (template._id)
                dispatch(
                    getTemplateReviews(template._id, {
                        sort: reviewSort,
                        page: (reviews.currentPage + 1).toString(),
                        resultsPerPage: reviewResultsPerPage.toString()
                    })
                );
    };

    const handleAddToCart = (id: string, values: ITemplateOrderBody) => {
        if (auth.authenticated) {
            if (selectedRadio === 'yes') {
                const body: IAddCartBody = {
                    model_id: id,
                    onModel: 'Template',
                    unit: 1,
                    self_order: true
                };

                dispatch(addToCart(body));
            } else if (selectedRadio === 'no') {
                const body: IAddCartBody = {
                    model_id: id,
                    onModel: 'Template',
                    unit: 1,
                    self_order: false,
                    ...values
                };
                dispatch(addToCart(body));
            }
        } else {
            history.push('/login', {
                from: location
            });
        }
    };

    const handleBuyNow = (values: ITemplateOrderBody) => {
        if (template._id) {
            let { name, relation, email, mobile_num } = values;

            if (selectedRadio === 'yes') {
                const body: IExpressCheckoutParams = {
                    model_id: template._id,
                    on_model: 'Template'
                };

                history.push({
                    pathname: '/express/checkout/cart',
                    search: qs.stringify(body)
                });
            } else if (selectedRadio === 'no') {
                const body: IExpressCheckoutParams = {
                    model_id: template._id,
                    on_model: 'Template',
                    self_order: false,
                    name,
                    relation,
                    email,
                    mobile_num
                };
                history.push({
                    pathname: '/express/checkout/cart',
                    search: qs.stringify(body)
                });
            }
        }
    };

    const initialValues: ITemplateOrderBody = {
        name: '',
        mobile_num: '',
        email: '',
        relation: relation[0].label
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        mobile_num: Yup.string()
            .required('Mobile number is required')
            .matches(phoneRegExp, 'Phone number is not valid')
    });

    const handleDropdown = (e: $FIXME) => {
        dispatch(resetTemplateReviews());

        setReviewSort(e);
    };

    return (
        <MainLayout>
            {template.slug === slug || !templateLoading ? (
                <>
                    <div className="container-xxl container-xl t-details-container">
                        <AppBreadcrumbs items={breadcrumbItems} />
                        <div className="mb">
                            <Link to="/template">
                                <BsArrowLeft size={24} />
                                <span className="text-oswald text-500 text-14 text-gray">
                                    BACK
                                </span>
                            </Link>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                if (template._id)
                                    handleAddToCart(template._id, values);
                            }}
                            isInitialValid={false}
                        >
                            {({
                                handleChange,
                                values,
                                isValid,
                                errors,
                                submitForm,
                                setFieldValue
                            }) => (
                                <Row className="mb-5">
                                    <Col md={5} lg={4} xl={3} className="mb-5">
                                        <div style={{ maxWidth: 320 }}>
                                            <AppTemplateCard
                                                title={template.title ?? 'N/A'}
                                                subtitle={
                                                    template.discount?.active &&
                                                    !!template.discounted_price &&
                                                    !!template.weeks ? (
                                                        <>
                                                            <s className="text-strike">
                                                                {`Rs. ${formatNumber(
                                                                    template.price
                                                                )}`}
                                                            </s>
                                                            <br />
                                                            {`Rs. ${formatNumber(
                                                                template.discounted_price
                                                            )} / ${
                                                                template.weeks
                                                            } Weeks`}
                                                        </>
                                                    ) : (
                                                        `Rs. ${formatNumber(
                                                            template.discounted_price
                                                        )} / ${
                                                            template.weeks
                                                        } Weeks`
                                                    )
                                                }
                                                img={
                                                    !!template.cover
                                                        ? template.cover
                                                        : defaultImage
                                                }
                                                imgTitle={
                                                    template?.training_category
                                                        ?.name
                                                }
                                                imgSubtitle={
                                                    template?.template_category
                                                        ?.name
                                                }
                                                ribbonText={
                                                    template.discount?.active
                                                        ? template.discount
                                                              .title
                                                        : ''
                                                }
                                            />

                                            <div className="d-flex mx-n1">
                                                <div className="w-100 mx-1 mb-2">
                                                    <AppButton
                                                        variant="outline-secondary"
                                                        className="template-btn "
                                                        onClick={() =>
                                                            handleBuyNow(values)
                                                        }
                                                        disabled={
                                                            selectedRadio ===
                                                                'no' && !isValid
                                                        }
                                                    >
                                                        Buy Now
                                                    </AppButton>
                                                </div>
                                                <div className="w-100 mx-1 mb-2">
                                                    <AppButton
                                                        variant="primary"
                                                        className="template-btn "
                                                        disabled={
                                                            selectedRadio ===
                                                                'no' && !isValid
                                                        }
                                                        onClick={
                                                            selectedRadio ===
                                                            'yes'
                                                                ? () => {
                                                                      if (
                                                                          template._id
                                                                      )
                                                                          handleAddToCart(
                                                                              template._id,
                                                                              values
                                                                          );
                                                                  }
                                                                : submitForm
                                                        }
                                                    >
                                                        {template.added_to_cart ||
                                                        carts.filter(
                                                            (cart) =>
                                                                cart.model_id ===
                                                                template._id
                                                        ).length > 0
                                                            ? 'Added To Cart'
                                                            : 'Add To Cart'}
                                                    </AppButton>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="template-details-main pl-xxl-5">
                                        <div className="mb-5 pt-3 ">
                                            <Row className="d-flex">
                                                <Col lg={8} className="mb-3">
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                                                            Template Details
                                                        </h2>
                                                        <div className="mb-2 d-flex align-items-end flex-wrap">
                                                            <Rating
                                                                emptySymbol={
                                                                    <AiOutlineStar
                                                                        size={
                                                                            18
                                                                        }
                                                                        className="text-yellow"
                                                                    />
                                                                }
                                                                fullSymbol={
                                                                    <AiFillStar
                                                                        size={
                                                                            18
                                                                        }
                                                                        className="text-yellow"
                                                                    />
                                                                }
                                                                fractions={2}
                                                                readonly
                                                                placeholderRating={
                                                                    0
                                                                }
                                                                initialRating={
                                                                    template.average_rating
                                                                }
                                                                className="mr-2"
                                                            />
                                                            <span
                                                                className="text-neutrif text-14 text-gray-1"
                                                                style={{
                                                                    lineHeight: 1.2
                                                                }}
                                                            >
                                                                (
                                                                {
                                                                    template.average_rating
                                                                }{' '}
                                                                Stars -{' '}
                                                                {reviews.totalDocuments ??
                                                                    0}{' '}
                                                                Reviews)
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className="text-gray text-14 mb-0">
                                                        Search and find the best
                                                        templates in town
                                                    </p>
                                                </Col>
                                                <Col className="mb-3">
                                                    <div className="d-flex flex-column align-items-lg-end justify-content-lg-end">
                                                        {template.discount
                                                            ?.discount_percentage &&
                                                        template.discount
                                                            .active ? (
                                                            <span className="text-neutrif text-14 text-dark-blue">
                                                                {
                                                                    template
                                                                        .discount
                                                                        .discount_percentage
                                                                }
                                                                % Off
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}

                                                        <span className="text-neutrif text-18 text-red">
                                                            {template.discounted_price &&
                                                            template.weeks ? (
                                                                <>
                                                                    Rs.{' '}
                                                                    {formatNumber(
                                                                        template.discounted_price
                                                                    )}
                                                                    <span className="text-dark text-14 text-500">
                                                                        {' '}
                                                                        /{' '}
                                                                        {
                                                                            template.weeks
                                                                        }{' '}
                                                                        Weeks
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </span>

                                                        <span className="text-neutrif text-500 text-16 text-dark-blue">
                                                            Total Weeks:{' '}
                                                            {template.weeks}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {readMore ? (
                                                <div>
                                                    <span className="text-neutrif text-dark-blue white-space-pre-line">
                                                        {
                                                            template.detail
                                                                ?.program_description
                                                        }
                                                    </span>

                                                    <span
                                                        onClick={() =>
                                                            setReadMore(false)
                                                        }
                                                        className="text-neutrif text-dark-blue text-600 text-underline cursor-pointer ml-2"
                                                    >
                                                        Read Less
                                                    </span>
                                                </div>
                                            ) : (
                                                <TruncateMarkup
                                                    lines={5}
                                                    ellipsis={
                                                        <span>
                                                            ...
                                                            <span
                                                                onClick={() =>
                                                                    setReadMore(
                                                                        true
                                                                    )
                                                                }
                                                                className="text-neutrif text-dark-blue text-600 text-underline cursor-pointer ml-2"
                                                            >
                                                                Read More
                                                            </span>
                                                        </span>
                                                    }
                                                >
                                                    <p className="white-space-pre-line">
                                                        {
                                                            template.detail
                                                                ?.program_description
                                                        }
                                                    </p>
                                                </TruncateMarkup>
                                            )}
                                        </div>

                                        <div className="mb-5">
                                            <h3 className="text-700 text-20 text-dark-blue text-uppercase mb-3">
                                                Buying for yourself?
                                            </h3>
                                            <div className="d-flex">
                                                <AppRadio
                                                    value="yes"
                                                    selected={selectedRadio}
                                                    onChange={
                                                        handleTemplateRadioChange
                                                    }
                                                    text="Yes! For Myself"
                                                    labelClassName="text-neutrif text-400 text-14 text-dark-blue"
                                                    className="mr-5"
                                                />
                                                <AppRadio
                                                    value="no"
                                                    selected={selectedRadio}
                                                    onChange={
                                                        handleTemplateRadioChange
                                                    }
                                                    text="For Other"
                                                    labelClassName="text-neutrif text-400 text-14 text-dark-blue"
                                                />
                                            </div>
                                            {selectedRadio === 'no' ? (
                                                <TemplateOrderForm
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="mb-5">
                                            <h3 className="text-700 text-20 text-dark-blue text-uppercase mb-3">
                                                What's Inside?
                                            </h3>
                                            <div className="template-contents-container p-3">
                                                <ul>
                                                    <li>
                                                        <span className="text-700">
                                                            Requirement:{' '}
                                                        </span>
                                                        {template.detail
                                                            ?.requirement ??
                                                            'N/A'}
                                                    </li>
                                                    <li>
                                                        <span className="text-700">
                                                            Difficulty:{' '}
                                                        </span>
                                                        {template.detail
                                                            ?.difficulty ??
                                                            'N/A'}
                                                    </li>
                                                    <li>
                                                        <span className="text-700">
                                                            Who is it for?:{' '}
                                                        </span>
                                                        {template.detail
                                                            ?.who_is_it_for ??
                                                            'N/A'}
                                                    </li>
                                                    <li>
                                                        <span className="text-700">
                                                            Lifting Frequency:{' '}
                                                        </span>
                                                        {template.detail
                                                            ?.lifting_frequency ??
                                                            'N/A'}
                                                    </li>
                                                    <li>
                                                        <span className="text-700">
                                                            Disclaimer:{' '}
                                                        </span>
                                                        {template.detail
                                                            ?.disclaimer ??
                                                            'N/A'}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex mb-3 flex-wrap">
                                                <h3 className="text-700 text-20 text-dark-blue text-uppercase mb-0 mr-3">
                                                    User's Reviews
                                                </h3>
                                                <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                                                    <p className="text-neutrif text-14 text-gray-1 mb-0 mr-2">
                                                        {reviews.totalDocuments ??
                                                            0}{' '}
                                                        reviews
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-3 text-neutrif text-14 text-gray-1">
                                                            Sort By
                                                        </span>
                                                        <DropdownButton
                                                            menuAlign="right"
                                                            title={
                                                                sortReviewFilter.find(
                                                                    (sort) =>
                                                                        sort.option ===
                                                                        reviewSort
                                                                )?.label ??
                                                                sortReviewFilter[0]
                                                                    .label
                                                            }
                                                            variant="outline-secondary"
                                                            size="sm"
                                                            className="template-review-dropdown"
                                                            onSelect={
                                                                handleDropdown
                                                            }
                                                        >
                                                            {sortReviewFilter.map(
                                                                (
                                                                    filter,
                                                                    index
                                                                ) => (
                                                                    <Dropdown.Item
                                                                        key={
                                                                            index
                                                                        }
                                                                        eventKey={
                                                                            filter.option
                                                                        }
                                                                        active={
                                                                            filter.option ===
                                                                            reviewSort
                                                                        }
                                                                    >
                                                                        {
                                                                            filter.label
                                                                        }
                                                                    </Dropdown.Item>
                                                                )
                                                            )}
                                                        </DropdownButton>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="template-contents-container p-3">
                                                <div className="mb-3">
                                                    {reviews.reviews.map(
                                                        (review, index) => (
                                                            <AppRatingCard
                                                                img={
                                                                    review.user
                                                                        ?.profile_pic ??
                                                                    'https://www.irishrsa.ie/wp-content/uploads/2017/03/default-avatar.png'
                                                                }
                                                                name={
                                                                    review.user
                                                                        ?.name ??
                                                                    ''
                                                                }
                                                                rating={
                                                                    review.stars ??
                                                                    0
                                                                }
                                                                username={
                                                                    review.user
                                                                        ?.email ??
                                                                    ''
                                                                }
                                                                review={
                                                                    review.review ??
                                                                    ''
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    {auth.authenticated ? (
                                                        <Button
                                                            variant="link"
                                                            className="text-oswald text-700 text-16 text-red px-0"
                                                            onClick={() =>
                                                                setAddReviewOpen(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            ADD YOUR REVIEW
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="link"
                                                            className="text-oswald text-700 text-16 text-red px-0"
                                                            onClick={() => {
                                                                history.push(
                                                                    '/login',
                                                                    {
                                                                        from: location
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            LOGIN TO ADD REVIEW
                                                        </Button>
                                                    )}

                                                    {reviews.currentPage ===
                                                        reviews.totalPage ||
                                                    reviews.totalDocuments ===
                                                        0 ? (
                                                        ''
                                                    ) : (
                                                        <Button
                                                            variant="link"
                                                            className="text-neutrif text-600 text-16 text-dark-blue text-underline"
                                                            disabled={
                                                                reviewsLoading
                                                            }
                                                            onClick={
                                                                handleLoadMoreReviews
                                                            }
                                                        >
                                                            Read more reviews
                                                        </Button>
                                                    )}
                                                </div>

                                                {auth.authenticated &&
                                                addReviewOpen ? (
                                                    <div className="mt-3">
                                                        <p className="text-14 text-dark-blue text-600">
                                                            Review text here
                                                        </p>
                                                        <AppInput
                                                            type="textarea"
                                                            placeholder="Your Review Here"
                                                            fill
                                                            as="textarea"
                                                            rows={5}
                                                            value={
                                                                myReview.review
                                                            }
                                                            onChange={
                                                                handleReviewChange
                                                            }
                                                        />
                                                        <div className="d-flex flex-wrap">
                                                            <div className="d-flex align-items-center flex-grow-1 mb-3 flex-wrap">
                                                                <p className="mb-0 text-600 text-14 mr-4">
                                                                    Rate this
                                                                    template
                                                                </p>
                                                                <Rating
                                                                    emptySymbol={
                                                                        <AiOutlineStar
                                                                            size={
                                                                                18
                                                                            }
                                                                            className="text-yellow"
                                                                        />
                                                                    }
                                                                    fullSymbol={
                                                                        <AiFillStar
                                                                            size={
                                                                                18
                                                                            }
                                                                            className="text-yellow"
                                                                        />
                                                                    }
                                                                    fractions={
                                                                        2
                                                                    }
                                                                    initialRating={parseFloat(
                                                                        myReview?.stars ??
                                                                            '0'
                                                                    )}
                                                                    className="mb-1 mr-2"
                                                                    onChange={
                                                                        handleRating
                                                                    }
                                                                />
                                                                {myReview.stars ? (
                                                                    <span className="text-14 text-black">
                                                                        You
                                                                        rated{' '}
                                                                        {
                                                                            myReview.stars
                                                                        }{' '}
                                                                        star out
                                                                        5 star
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                            <div className="mb-3">
                                                                <AppButton
                                                                    variant="primary"
                                                                    onClick={
                                                                        handleReviewSubmit
                                                                    }
                                                                    disabled={
                                                                        !isReviewValid()
                                                                    }
                                                                >
                                                                    Save Your
                                                                    Review
                                                                </AppButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Formik>
                    </div>
                    <SimilarTemplateList />
                </>
            ) : (
                <div
                    style={{ height: 'calc(100vh - 87px)' }}
                    className="d-flex align-items-center justify-content-center"
                >
                    <Spinner animation="border" />
                </div>
            )}

            <GetOffers offerType="template" />
            <Offers />
            <GetNotified />
        </MainLayout>
    );
};

export default TemplateDetails;
